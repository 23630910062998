* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Overpass', sans-serif;
}

body {
    min-width: 1441px;
}

.container {
    width: 1198px;
    margin: 0 auto;
}

.spanLink {
    color: #333333;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
}

.header {
    background: url("./assets/img/headerBG.png") no-repeat;
    margin: 0 auto;
    width: 1400px;
}

.header--logo-img {
    width: 434px;
    height: 180px;
}

.header--title {
    margin-top: 165px;
    color: #ffffff;
    font-size: 119px;
    font-weight: 700;
    line-height: 100%;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, .25);
}

.header--info {
    color: #ffffff;
    font-size: 37px;
    font-weight: 400;
    line-height: 37px;
    display: inline-block;
    margin-top: 24px;
    padding: 40px;
    background-color: #D30E51;
    position: relative;
}

.header--info:before {
    content: '*';
    position: absolute;
    font-size: 36px;
    top: 76px;
    right: 21px;
}

.header--info:after {
    content: '1';
    position: absolute;
    font-size: 20px;
    top: 64px;
    right: 12px;
}

.header--description:not(:last-child) {
    padding-top: 16px;
}
.header--description:last-child {
    padding-bottom: 4px;
}

.header--description {
    color: #333333;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
}

.header--description > a {
    color: #333333;
}

.video {
    margin-top: 142px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
}

.video--frameWrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}

.video--frameWrapper > iframe {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.video--button {
    display: inline-block;
    margin: 0 auto 85px;
    padding: 20px 60px;
    color: #ffffff;
    font-size: 38px;
    line-height: 125%;
    font-weight: 400;
    background: #D30E51;
    text-decoration: none;
    text-align: center;
    border-radius: 0 0 50% 50% / 0 0 100% 100%;
    box-shadow: inset 0 13px 25px #000000;
    position: relative;
    cursor: pointer;
}
.video--button span {
    font-weight: 900;
}

.video--button:after {
    content: '';
    position: absolute;
    width: 64px;
    height: 64px;
    bottom: -45px;
    left: calc(50% - 32px);
    background: url("./assets/img/buttonIcon.png");
}

.cards {
    padding-top: 100px;
    background: #f5f5f5;
}

.cards--wrapper {
    display: flex;
    flex-direction: row;
}

.cards--title {
    text-decoration: underline;
    text-transform: uppercase;
    color: #505050;
    font-size: 46px;
    line-height: 58px;
    font-weight: 400;
    margin-bottom: 60px;
}

.cards--ls, .cards--rs {
    width: 50%;
}

.cards--rs {
    padding-top: 115px;
}

.cards--left {
    margin-right: 22px;
}

.cards--right {
    margin-left: 22px;
}

.cards--link {
    text-decoration: none;
    color: #ffffff;
    height: 352px;
    padding: 50px 40px;
    background: url("./assets/img/cardBG.png") no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 45px;
    cursor: pointer;
}

.cards--link-title {
    font-size: 28px;
    line-height: 28px;
    font-weight: 700;
    text-align: center;
}

.cards--link-description {
    margin-top: 33px;
    font-size: 28px;
    line-height: 27px;
    font-weight: 400;
    text-align: center;
}

.footer {
    background-color: #ffffff;
    padding: 40px 0 35px;
    margin: 0 auto;
    width: 1400px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer--logo {
    margin-right: 65px;
}

.footer--textarea {
    display: flex;
    flex-direction: column;
}

.footer--textarea-text {
    color: #333333;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
}

.footer--textarea-mNumber {
    color: #333333;
    margin-top: 9px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: right;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup--modal {
    width: 500px;
    min-height: 150px;
    border-radius: 6px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, .5);
    background-color: #ffffff;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.popup--modal-title {
    text-align: center;
    color: #333333;
}

.popup--modal-description {
    color: #333333;
    font-size: 12px;
    margin: 20px 0;
}

.popup--modal-button {
    background-color: transparent;
    border: none;
    color: #333333;
    font-size: 16px;
    cursor: pointer;
}

.popup--modal-link {
    text-decoration: none;
    color: #333333;
}

.popup--modal > div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
